@import url('https://fonts.googleapis.com/css2?family=Short+Stack&display=swap');

.letter-box {
    border-radius: 3px;
    background: #fff;
    border: 1px solid #999;
    float: left;
    font-size: 45px;
    font-weight: bold;
    /*height: 60px;*/
    padding: 0;
    margin: 2px;
    text-align: center;
    max-width: 60px;
    transition: 1s;
    line-height: 1.3;
    aspect-ratio: 1/1;
}

.match {
    background-color: #458c58;
    border: 0px;
}
.present {
    background-color: #ebba86;
    border: 0px;
}
.absent {
    background-color: #3d3d3d;
    border: 0px;
}
.flip{
    animation: flip 0.75s linear;
}
@keyframes flip{
    from{
        transform: rotateY(0deg);
    }
    to{
        transform: rotateY(360deg);
    }
}

.main-page {
    margin-top: 5pt;
    max-width: 450px;
}

.center-block {
    margin: auto;
    display: block;
}

.board {
    /*margin-bottom: 15px;*/
}

.bi-gear, .bi-file-bar-graph {
    font-size: 1.5em;
    padding: 10px;
    border-radius: 5px;
}

.bi-gear:active, .bi-file-bar-graph:active {
    background-color: lightgray;
}

.game-header {
    border-bottom: 1px #3d3d3d solid;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: 'Short Stack', cursive;
}

div.shake{
    animation: shake 0.5s infinite;
}
@keyframes shake {
    0%  { transform: translate(2px, 1px)   rotate(0deg);  }
    10% { transform: translate(-1px, -2px) rotate(-2deg); }
    20% { transform: translate(-3px, 0px)  rotate(3deg);  }
    30% { transform: translate(0px, 2px)   rotate(0deg);  }
    40% { transform: translate(1px, -1px)  rotate(1deg);  }
    50% { transform: translate(-1px, 2px)  rotate(-1deg); }
    60% { transform: translate(-3px, 1px)  rotate(0deg);  }
    70% { transform: translate(2px, 1px)   rotate(-2deg); }
    80% { transform: translate(-1px, -1px) rotate(4deg);  }
    90% { transform: translate(2px, 2px)   rotate(0deg);  }
    100%{ transform: translate(1px, -2px)  rotate(-1deg); }
}
