.danger-zone{
    border-top: 3px black solid;
    padding-top: 15px;
}

.settings-row {
    margin-top: 10px;
    margin-bottom: 10px;
}

.capitalize-me::first-letter {
    text-transform:capitalize;
}

.stats-head {
    font-size: 9pt;
}

.center-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.info-text {
    font-style: italic;
    font-size: 8pt;
}