.keyboard-box{
    width: 100%;
    display: block;
    /*margin-top: 20px;*/
}

.keyboard-row {
    display: flex;
    width: 100%;
    margin: 0 auto 8px;
    touch-action: manipulation;
}

.keyboard-key{
    font-family: inherit;
    font-weight: bold;
    border: 0;
    padding: 0;
    margin: 0 6px 0 0;
    height: 58px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}

.keyboard-key:active{
    background-color: darkgray;
}

.bksp {
    flex: 1.5;
    font-size: 1.5em;
}

.ent {
    font-size: 1.5em;
    flex: 1.5;
}

.keyboard-key .bi{
    pointer-events: none;
}